import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white">
      <div className="max-w-6xl mx-auto px-4 py-10">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-6 md:mb-0">
            <h2 className="text-2xl font-bold">Safari Bookings</h2>
            <p className="mt-2">Experience the wild like never before</p>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-4">
            <a href="#" className="hover:text-green-500 transition duration-300">Privacy Policy</a>
            <a href="#" className="hover:text-green-500 transition duration-300">Terms of Service</a>
            <a href="#" className="hover:text-green-500 transition duration-300">Contact Us</a>
          </div>
        </div>
        <div className="mt-8 text-center">
          <p>&copy; {new Date().getFullYear()} Safari Bookings. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
