import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from "../components/ui/button";
import { motion } from 'framer-motion';
import { FaBinoculars, FaFeatherAlt, FaMapMarkedAlt, FaSpider } from 'react-icons/fa';

const Home = () => {
  return (
    <div>
      {/* Hero Section with Background Image */}
      <motion.div 
        className="relative bg-cover bg-center h-[70vh] flex items-center"
        style={{backgroundImage: "url('https://srilankabasecamp.com/wp-content/uploads/2024/02/safari_in_sri_lanka.jpg')"}}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="container mx-auto px-4 z-10 text-center text-white">
          <motion.h1 
            className="text-4xl md:text-5xl font-bold mb-4"
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.8 }}
          >
            Experience the Wild with Leopard Yala Safari
          </motion.h1>
          <motion.p 
            className="text-lg md:text-xl mb-8 max-w-2xl mx-auto"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.8 }}
          >
            Embark on an unforgettable journey through nature's most breathtaking
            landscapes and encounter majestic wildlife in Yala National Park.
          </motion.p>
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ delay: 0.6, duration: 0.5 }}
          >
            <Button asChild size="lg" className="bg-white text-black hover:bg-gray-200">
              <Link to="/booking">Book Your Safari Adventure</Link>
            </Button>
          </motion.div>
        </div>
      </motion.div>

      {/* Yala's Wildlife Section */}
      <motion.div 
        className="bg-gray-100 py-16"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <div className="container mx-auto px-4">
          <motion.h2 
            className="text-3xl font-bold mb-8 text-center"
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            WAY MORE THAN JUST LEOPARDS: YALA'S 'OTHER' WILDLIFE
          </motion.h2>
          <motion.p 
            className="text-lg mb-6 text-gray-700"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.5 }}
          >
            Leopard included, Yala boasts an impressive 44 mammal species. You'll surely encounter Sri Lankan elephants — with around 350 calling Yala home — along with wild water buffalo, wild boar, spotted deer, and sambar (imagine the UK's red deer, but darker and bigger).
          </motion.p>
          <motion.p 
            className="text-lg mb-6 text-gray-700"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.6, duration: 0.5 }}
          >
            If fortune favors you, you might spot the elusive golden palm civet or the red slender loris. And let's not forget Yala's other big attraction: the sloth bear. These usually shy, solitary bears throw caution to the wind when Palu fruit is in season. Time your visit right, and you'll enjoy a unique 'teddy bears' picnic'!
          </motion.p>
        </div>
      </motion.div>

      {/* Featured Safaris Section */}
      <div className="container mx-auto px-4 py-16">
        <motion.h2 
          className="text-3xl font-bold mb-8 text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          Our Safari Packages
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <SafariCard
            title="Yala Safari"
            description="Explore Sri Lanka's most famous national park"
            buttonText="Book Yala Safari"
            link="/booking"
            image="/images/yala-safari.jpg"
          />
          <SafariCard
            title="Bundala Safari"
            description="Discover the wetlands and migratory birds"
            buttonText="Book Bundala Safari"
            link="/booking"
            image="/images/bundala-safari.jpg"
          />
          <SafariCard
            title="Custom Safari"
            description="Create your own unique wildlife experience"
            buttonText="Inquire Now"
            link="/contact"
            image="/images/image4.jpg"
          />
        </div>
      </div>

      {/* Birdwatching Section */}
      <motion.div 
        className="bg-gray-100 py-16"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <div className="container mx-auto px-4">
          <motion.h2 
            className="text-3xl font-bold mb-8 text-center"
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            BIRDWATCHING IN YALA
          </motion.h2>
          <motion.p 
            className="text-lg mb-6 text-gray-700"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.5 }}
          >
            Officially one of Sri Lanka's 70 Important Bird Areas (IBAs), Yala's biodiversity creates a haven for birdlife and birders. Some 215 bird species have been recorded, including six endemics: the Sri Lankan grey hornbill, Sri Lankan junglefowl, Sri Lankan wood pigeon, black-capped bulbul, brown-capped babbler, and crimson-fronted barbet (listen out for its constant 'morse code-style' pips!).
          </motion.p>
          <motion.p 
            className="text-lg mb-6 text-gray-700"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.6, duration: 0.5 }}
          >
            And come the winter months, hundreds of migratory species call by, too. From pelicans, peafowls, and peregrine falcons to spoonbills, sunbirds, and serpent eagles, Yala's avian array is truly astonishing. If you've never been bothered by birds, prepare to be blown away.
          </motion.p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8">
            <BirdwatchingFeature
              icon={<FaBinoculars className="text-4xl mb-4 text-green-600" />}
              title="215+ Bird Species"
              description="Discover a vast array of bird species in their natural habitat."
            />
            <BirdwatchingFeature
              icon={<FaFeatherAlt className="text-4xl mb-4 text-green-600" />}
              title="6 Endemic Species"
              description="Spot unique birds found only in Sri Lanka."
            />
            <BirdwatchingFeature
              icon={<FaMapMarkedAlt className="text-4xl mb-4 text-green-600" />}
              title="Important Bird Area"
              description="Explore one of Sri Lanka's 70 recognized Important Bird Areas."
            />
          </div>
        </div>
      </motion.div>

      {/* Yala's Reptiles Section */}
      <motion.div 
        className="py-16"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <div className="container mx-auto px-4">
          <motion.h2 
            className="text-3xl font-bold mb-8 text-center"
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            YALA'S REPTILES
          </motion.h2>
          <motion.p 
            className="text-lg mb-6 text-gray-700"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.5 }}
          >
            A hotspot for reptiles, Yala writhes with scaly surprises. From mugger and saltwater crocodiles, Indian cobras, and fan-throated lizards to all five globally endangered types of sea turtle, the park's 47 known reptile species range from decidedly dangerous to beautifully benign. And yes, we do sleep easier knowing that the Sri Lankan flying snake — one of Yala's five endemic species — is only 'mildly' venomous.
          </motion.p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8">
            <ReptileFeature
              icon={<FaSpider className="text-4xl mb-4 text-green-600" />}
              title="47 Reptile Species"
              description="Discover a diverse range of reptiles in their natural habitat."
            />
            <ReptileFeature
              icon={<FaSpider className="text-4xl mb-4 text-green-600" />}
              title="5 Endemic Species"
              description="Encounter reptiles found only in Sri Lanka, including the flying snake."
            />
            <ReptileFeature
              icon={<FaSpider className="text-4xl mb-4 text-green-600" />}
              title="Endangered Sea Turtles"
              description="Witness all five globally endangered types of sea turtles."
            />
          </div>
        </div>
      </motion.div>

      {/* Yala's Layout Section */}
      <motion.div 
        className="py-16 bg-gray-100"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <div className="container mx-auto px-4">
          <motion.h2 
            className="text-3xl font-bold mb-8 text-center"
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            BLOCKING IT OUT: YALA'S LAYOUT EXPLAINED
          </motion.h2>
          <motion.p 
            className="text-lg mb-6 text-gray-700"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.5 }}
          >
            The size of the City of Los Angeles, Yala's 469 miles² are divided into six 'blocks' — but only blocks one, five, and six are easily accessible to visitors. To enter block two, you need prior permission plus two 4WD jeeps. Block three is currently shut. Block four is a nature reserve and off-limits to the general public.
          </motion.p>
          <motion.p 
            className="text-lg mb-6 text-gray-700"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.6, duration: 0.5 }}
          >
            Given Yala's size, where you stay will define where you can explore. Each block has pros and cons, so consider these when choosing accommodation.
          </motion.p>
          <motion.div 
            className="bg-white p-6 rounded-lg shadow-md mb-8"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.8, duration: 0.5 }}
          >
            <h3 className="text-xl font-semibold mb-4">YALA BLOCK ONE</h3>
            <ul className="list-disc list-inside mb-4">
              <li className="text-green-600">Best for leopard spotting</li>
              <li className="text-red-600">It can get VERY busy</li>
            </ul>
            <div className="flex flex-col md:flex-row gap-8">
              <div className="md:w-1/2">
                <p className="text-gray-700 mb-4">
                  With by far the most leopard sightings, Yala's main block also gets the most visitors. Access is via one of two gates: Palatupana (the southern and busiest entrance) and Katagamuwa. As most hotels are based around Palatupana, the gate here can get very congested; be prepared for traffic and queues. On the upside, leopards are often spotted in this southern end — just don't expect a private show. Chances are you'll be bumper to bumper with other vehicles, especially over weekends and public holidays when local traffic sees visitor numbers soar.
                </p>
                <p className="text-gray-700">
                  With far less accommodation nearby, the north-eastern gate of Katagamuwa makes for a quieter entrance. Leopard sightings are good here, too, so we think it's a much better option. (But be warned: stay near Palatupuna and you'll need an early wake-up call — Katagamuwa is a 45-minute drive.) While this side of the park can be a little busy, over-crowding is less of an issue. The main problems occur when Katagamuwa's cats' are spotted while Palatupana's are playing hard to get. If this happens, jeeps will stream up from the south — but you should get an hour or two's grace before the hordes arrive.
                </p>
              </div>
              <div className="md:w-1/2">
                <img 
                  src="/images/block.webp" 
                  alt="Yala Block One" 
                  className="w-full h-auto rounded-lg shadow-md"
                />
              </div>
            </div>
          </motion.div>

          {/* Yala Blocks Five and Six */}
          <motion.div 
            className="bg-white p-6 rounded-lg shadow-md mb-8"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 1, duration: 0.5 }}
          >
            <h3 className="text-xl font-semibold mb-4">YALA BLOCKS FIVE AND SIX</h3>
            <ul className="list-disc list-inside mb-4">
              <li className="text-green-600">Much quieter. Great for elephants and birdlife</li>
              <li className="text-red-600">Unlikely to see leopards or sloth bears in Block Six</li>
            </ul>
            <div className="flex flex-col md:flex-row gap-8">
              <div className="md:w-1/2">
                <p className="text-gray-700 mb-4">
                  With far fewer visitors (the commute from Palatupana would take hours), these two blocks offer a much more intimate, 'traditional' safari experience. Both blocks are accessible via the Galge gate in Buttala (a 45-minute drive from Katagamuwa). Block Six — aka Lunungamwehera National Park/Yala West — also has a main gate on the Hambantota - Wellawaya Road near Thanamalwila. Importantly, there is no direct entry from Block One, so even if Martians land, you'll have ages before the crowds get wind!
                </p>
                <p className="text-gray-700 mb-4">
                  Offering a deep dive into nature, the wildlife here can be spectacular but quite shy. This is especially the case in Block Six — by far the least visited block — where sloth bear and leopard are seldom seen. In Block Five (where the animals are more habituated to traffic), leopard sightings are still fair/good, but having a professional guide/tracker will up your chances of striking lucky.
                </p>
                <p className="text-gray-700">
                  What you can find here are elephants aplenty (particularly in block six), along with deer, buffalo, giant squirrel, palm cat, and grey, brown, and striped-necked mongoose. Both blocks are rich with amphibians and reptiles, including crocodiles, water and land monitors, Indian python, Russell's viper, and choruses of croaking frogs. And if it's birds you're after, don't miss Block Six's reservoir, which attracts countless water and forest birds (184 species and counting), especially over our winter.
                </p>
              </div>
              <div className="md:w-1/2">
                <img 
                  src="/images/yala-blocks-five-six.webp"
                  alt="Yala Blocks Five and Six" 
                  className="w-full h-auto rounded-lg shadow-md"
                />
              </div>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

const SafariCard = ({ title, description, buttonText, link, image }) => {
  return (
    <motion.div 
      className="bg-white shadow-lg rounded-lg overflow-hidden"
      initial={{ scale: 0.9, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ duration: 0.5 }}
      whileHover={{ scale: 1.05 }}
    >
      <div className="h-48 bg-gray-200">
        <img src={image} alt={title} className="w-full h-full object-cover" />
      </div>
      <div className="p-6">
        <h3 className="font-bold text-xl mb-2">{title}</h3>
        <p className="text-gray-600 mb-4">{description}</p>
        <Button asChild className="w-full bg-black text-white hover:bg-gray-800">
          <Link to={link}>{buttonText}</Link>
        </Button>
      </div>
    </motion.div>
  );
};

const BirdwatchingFeature = ({ icon, title, description }) => {
  return (
    <motion.div 
      className="text-center"
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {icon}
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </motion.div>
  );
};

const ReptileFeature = ({ icon, title, description }) => {
  return (
    <motion.div 
      className="text-center"
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {icon}
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </motion.div>
  );
};

export default Home;
