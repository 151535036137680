import React, { useState } from 'react';
import { Button } from "../components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/ui/tabs";
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const Safari = () => {
  const [activeTab, setActiveTab] = useState('yala');
  const navigate = useNavigate();

  const safariPackages = {
    yala: {
      title: "Yala National Park",
      description: "Explore Sri Lanka's most famous national park, home to a diverse array of wildlife including leopards, elephants, and exotic birds.",
      image: "https://findit-resources.s3.amazonaws.com/forums/1656997220132.jpg",
      tours: [
        { name: "Morning Safari Tour (4 Hours)", description: "Experience the park as it awakens", time: "4:30 AM - 10:00 AM" },
        { name: "Afternoon Safari (4 Hours)", description: "Witness the golden hour in the wild", time: "1:45 PM - 6:00 PM" },
        { name: "6 Hours Morning Safari", description: "Extended tour for wildlife enthusiasts", time: "4:30 AM - 12:00 PM" },
        { name: "Full Day Safari (Lunch & Cool Water included)", description: "Immersive experience with lunch included", time: "4:30 AM - 6:00 PM" },
      ]
    },
    bundala: {
      title: "Bundala National Park",
      description: "Discover the wetlands and migratory birds in this UNESCO biosphere reserve.",
      longDescription: "Bundala National Park is a fantastic maze of waterways, lagoons and dunes that glitter like gold in the dying evening sun. This wonderland provides a home to thousands of colourful birds ranging from diminutive little bee-eaters to grotesque open-billed stalks. It is a wetland sanctuary of such importance that it has been recognised under the Ramsar Convention on Wetlands. Many big mammals are also present, with between 15 and 60 elephants depending on the season (December is the best month).",
      image: "https://merganserholidays.com/wp-content/uploads/2021/01/bundala-safari.jpg",
      tours: [
        { name: "Morning Safari Tour (4 Hours)", description: "Observe the early bird activities" },
        { name: "Afternoon Safari (4 Hours)", description: "Experience the park's tranquil evening" },
        { name: "6 Hours Morning Safari", description: "Extended tour for birdwatching enthusiasts" },
        { name: "Full Day Safari", description: "Complete wetland experience with lunch" },
      ]
    }
  };

  const handleBooking = (parkName, tourName) => {
    navigate('/booking', {
      state: {
        park: parkName,
        tour: tourName
      }
    });
    
    console.log(`Booking ${tourName} for ${parkName}`);
  };

  return (
    <motion.div 
      className="container mx-auto px-4 py-8"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <motion.h1 
        className="text-4xl font-bold mb-8 text-center"
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.5 }}
      >
        Our Safari Packages
      </motion.h1>
      
      <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="yala">Yala Safari</TabsTrigger>
          <TabsTrigger value="bundala">Bundala Safari</TabsTrigger>
        </TabsList>
        {Object.entries(safariPackages).map(([key, safariPackage]) => (
          <TabsContent key={key} value={key}>
            <motion.div 
              className="mt-8"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <h2 className="text-3xl font-semibold mb-4">{safariPackage.title}</h2>
              <p className="text-lg mb-6">{safariPackage.description}</p>
              <img src={safariPackage.image} alt={safariPackage.title} className="w-full h-64 object-cover rounded-lg mb-8" />
              
              {key === 'yala' && (
                <div className="bg-gray-100 p-6 rounded-lg mb-8">
                  <h3 className="text-2xl font-semibold mb-4">Leopard Yala Safari Offerings</h3>
                  <p className="mb-4">Leopard Yala Safari offers four different safari packages for exploring Yala National Park:</p>
                  <ul className="list-disc list-inside mb-4">
                    <li>Yala Morning Safari (4 hours)</li>
                    <li>Yala Afternoon Safari (4 hours)</li>
                    <li>Yala 6 Hours Safari</li>
                    <li>Yala Full Day Safari</li>
                  </ul>
                  <p className="mb-4">For Full Day Safari bookings, our organization provides complimentary Sri Lankan rice and curry for lunch, along with a cool box with water. Additionally, breakfast can be arranged and booked for any safari package of your choice.</p>
                  <p className="mb-4">Regardless of which safari package you select, your payment covers only the safari jeep service from our organization. You will need to obtain separate entrance tickets to enter the safari park. For detailed information about entrance fees and other requirements, please contact us through WhatsApp.</p>
                  <p className="font-semibold">Contact: Leopard Yala Safari</p>
                  <p className="font-semibold">WhatsApp: +94714907880</p>
                </div>
              )}

              {key === 'bundala' && (
                <div className="bg-gray-100 p-6 rounded-lg mb-8">
                  <h3 className="text-2xl font-semibold mb-4">About Bundala National Park</h3>
                  <p className="mb-4">{safariPackage.longDescription}</p>
                </div>
              )}
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {safariPackage.tours.map((tour, index) => (
                  <div key={index} className="bg-white shadow-md rounded-lg p-6">
                    <h3 className="text-xl font-semibold mb-2">{tour.name}</h3>
                    <p className="text-gray-600 mb-2">{tour.description}</p>
                    {tour.time && <p className="text-gray-600 mb-4">Time: {tour.time}</p>}
                    <Button 
                      className="w-full bg-black text-white hover:bg-gray-800"
                      onClick={() => handleBooking(safariPackage.title, tour.name)}
                    >
                      Book Now
                    </Button>
                  </div>
                ))}
              </div>
            </motion.div>
          </TabsContent>
        ))}
      </Tabs>
    </motion.div>
  );
};

export default Safari;
