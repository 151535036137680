import React, { useState, useEffect } from 'react';
import { Button } from "../components/ui/button";
import emailjs from '@emailjs/browser';

const Booking = () => {
  const [formData, setFormData] = useState({
    package: 'Yala Safari',
    subPackage: '',
    transportOption: 'Private Jeep',
    passengers: 1,
    breakfast: false,
    lunch: false,
    fullName: '',
    email: '',
    phone: '',
    country: '',
    preferredDate: '',
  });

  const [totalPrice, setTotalPrice] = useState(0);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const subPackages = [
    "Morning Safari Tour (4 Hours)",
    "Afternoon Safari (4 Hours)",
    "6 Hours Morning Safari",
    "Full Day Safari (Lunch & Cool Water included)"
  ];

  const calculatePrice = () => {
    let basePrice = 0;
    const { subPackage, transportOption, passengers, breakfast, lunch } = formData;

    if (subPackage.includes("4 Hours") || subPackage.includes("6 Hours Morning Safari")) {
      if (transportOption === 'Private Jeep') {
        basePrice = subPackage.includes("4 Hours") ? 68 : 75;
      } else if (transportOption === 'Shared Jeep') {
        switch (parseInt(passengers)) {
          case 1:
          case 2:
            basePrice = 58;
            break;
          case 3:
            basePrice = 87;
            break;
          case 4:
            basePrice = 116;
            break;
          case 5:
            basePrice = 145;
            break;
          case 6:
            basePrice = 174;
            break;
          default:
            basePrice = 58; // Default to 2 pax price if something goes wrong
        }
      }
    } else if (subPackage.includes("Full Day")) {
      if (transportOption === 'Private Jeep') {
        basePrice = 110;
      } else if (transportOption === 'Shared Jeep') {
        switch (parseInt(passengers)) {
          case 1:
          case 2:
            basePrice = 80;
            break;
          case 3:
            basePrice = 120;
            break;
          case 4:
            basePrice = 160;
            break;
          case 5:
            basePrice = 200;
            break;
          case 6:
            basePrice = 240;
            break;
          default:
            basePrice = 80; // Default to 2 pax price if something goes wrong
        }
      }
    }

    const mealCost = (breakfast ? 5 : 0) + (lunch ? 10 : 0);
    const totalCost = basePrice + (mealCost * passengers);

    setTotalPrice(totalCost);
  };

  useEffect(() => {
    calculatePrice();
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus('submitting');
    setErrorMessage('');

    const templateParams = {
      ...formData,
      totalPrice: totalPrice.toFixed(2),
      breakfast: formData.breakfast ? 'Yes' : 'No',
      lunch: formData.lunch ? 'Yes' : 'No'
    };

    try {
      const result = await emailjs.send(
        'service_kajj8oa', 
        'template_dbr9h3f', 
        templateParams, 
        'LmIVRZNrY-mILNLjf'
      );
      console.log('Email successfully sent!', result.text);
      setSubmitStatus('success');
      // Reset form after successful submission
      setFormData({
        package: 'Yala Safari',
        subPackage: '',
        transportOption: 'Private Jeep',
        passengers: 1,
        breakfast: false,
        lunch: false,
        fullName: '',
        email: '',
        phone: '',
        country: '',
        preferredDate: '',
      });
    } catch (error) {
      console.error('Failed to send email:', error);
      setSubmitStatus('error');
      setErrorMessage(`Failed to submit booking: ${error.text}`);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-8 text-center">Book Your Safari</h1>
      
      <form onSubmit={handleSubmit} className="max-w-2xl mx-auto bg-white shadow-lg rounded-lg p-8">
        <h2 className="text-2xl font-semibold mb-6">Booking Details</h2>
        <p className="mb-6 text-gray-600">Fill in the details to book your safari adventure.</p>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Select Package</label>
          <div className="mt-2">
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="package"
                value="Yala Safari"
                checked={formData.package === 'Yala Safari'}
                onChange={handleInputChange}
                className="form-radio"
              />
              <span className="ml-2">Yala Safari</span>
            </label>
            <label className="inline-flex items-center ml-6">
              <input
                type="radio"
                name="package"
                value="Bundala Safari"
                checked={formData.package === 'Bundala Safari'}
                onChange={handleInputChange}
                className="form-radio"
              />
              <span className="ml-2">Bundala Safari</span>
            </label>
          </div>
        </div>

        <div className="mb-4">
          <label htmlFor="subPackage" className="block text-sm font-medium text-gray-700">Select Sub-Package</label>
          <select
            id="subPackage"
            name="subPackage"
            value={formData.subPackage}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          >
            <option value="">Select a sub-package</option>
            {subPackages.map((pkg, index) => (
              <option key={index} value={pkg}>{pkg}</option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Transportation Option</label>
          <div className="mt-2">
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="transportOption"
                value="Private Jeep"
                checked={formData.transportOption === 'Private Jeep'}
                onChange={handleInputChange}
                className="form-radio"
              />
              <span className="ml-2">Private Jeep</span>
            </label>
            <label className="inline-flex items-center ml-6">
              <input
                type="radio"
                name="transportOption"
                value="Shared Jeep"
                checked={formData.transportOption === 'Shared Jeep'}
                onChange={handleInputChange}
                className="form-radio"
              />
              <span className="ml-2">Shared Jeep</span>
            </label>
          </div>
        </div>

        <div className="mb-4">
          <label htmlFor="passengers" className="block text-sm font-medium text-gray-700">Number of Passengers</label>
          <input
            type="number"
            id="passengers"
            name="passengers"
            min="1"
            max="6"
            value={formData.passengers}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Meal Options</label>
          <div className="mt-2">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                name="breakfast"
                checked={formData.breakfast}
                onChange={handleInputChange}
                className="form-checkbox"
              />
              <span className="ml-2">Breakfast ($5 per person)</span>
            </label>
          </div>
          <div className="mt-2">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                name="lunch"
                checked={formData.lunch}
                onChange={handleInputChange}
                className="form-checkbox"
              />
              <span className="ml-2">Lunch ($10 per person)</span>
            </label>
          </div>
        </div>

        <div className="mb-4">
          <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">Full Name</label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            value={formData.fullName}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Phone</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="country" className="block text-sm font-medium text-gray-700">Country</label>
          <input
            type="text"
            id="country"
            name="country"
            value={formData.country}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="preferredDate" className="block text-sm font-medium text-gray-700">Preferred Date</label>
          <input
            type="date"
            id="preferredDate"
            name="preferredDate"
            value={formData.preferredDate}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>

        <div className="mb-6">
          <h3 className="text-lg font-semibold">Total Price: ${totalPrice.toFixed(2)}</h3>
        </div>

        <Button 
          type="submit" 
          className="w-full bg-black text-white hover:bg-gray-800"
          disabled={submitStatus === 'submitting'}
        >
          {submitStatus === 'submitting' ? 'Submitting...' : 'Book Now'}
        </Button>

        {submitStatus === 'success' && (
          <p className="mt-4 text-green-600">Booking submitted successfully!</p>
        )}
        {submitStatus === 'error' && (
          <p className="mt-4 text-red-600">{errorMessage}</p>
        )}
      </form>
    </div>
  );
};

export default Booking;
