import React from 'react';
import { motion } from 'framer-motion';

const About = () => {
  return (
    <motion.div 
      className="container mx-auto px-4 py-8"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <motion.h1 
        className="text-4xl font-bold mb-8 text-center"
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.5 }}
      >
        About Leopard Yala Safari
      </motion.h1>
      
      <div className="flex flex-col md:flex-row gap-8 mb-16">
        <motion.div 
          className="md:w-1/2"
          initial={{ x: -50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          <div className="bg-gray-200 h-64 md:h-full rounded-lg overflow-hidden">
            <img 
              src="https://summit97.com/images2/yala002.jpg" 
              alt="Leopard Yala Safari" 
              className="w-full h-full object-cover"
            />
          </div>
        </motion.div>
        <motion.div 
          className="md:w-1/2"
          initial={{ x: 50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ delay: 0.6, duration: 0.5 }}
        >
          <h2 className="text-3xl font-semibold mb-4">Our Story</h2>
          <p className="text-gray-600 mb-4">
            Leopard Yala Safari introduces two safari packages for your consideration: Yala Private Safari and Yala Shared Safari.
          </p>
          <p className="text-gray-600 mb-4">
            For the Yala Private Safari package, when booking a jeep, you can accommodate up to 6 people in one vehicle. However, if only two people are traveling, we will provide the jeep according to your booking requirements. We offer pickup and drop-off services directly from your accommodation in areas including Tissamaharama, Weerawila, Katharagama, and Kirinda.
          </p>
          <p className="text-gray-600 mb-4">
            Our safari timings are flexible, and if you need to adjust the safari schedule, we can check availability upon inquiry. We provide English-speaking drivers who are highly knowledgeable about safaris for your service.
          </p>
          <p className="text-gray-600 mb-4">
            For the Yala Shared Safari package, since the jeep can accommodate 6 people, our organization will arrange shared transportation based on your booking requirements. Regardless of which safari package you choose, when booking a jeep with Leopard Yala Safari, the package fee must be paid through online banking methods. Additionally, you will need to pay the park entrance fee separately depending on which safari park you visit. You can inquire for more details about these fees.
          </p>
          <p className="text-gray-600 mb-4">
            Our organization provides all this information to ensure we deliver a high-quality service to our valued customers.
          </p>
        </motion.div>
      </div>

      <motion.h2 
        className="text-3xl font-semibold mb-8 text-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.8, duration: 0.5 }}
      >
        Our Team
      </motion.h2>
      <motion.div 
        className="max-w-md mx-auto"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 1, duration: 0.5 }}
      >
        <TeamMember
          name="Mr. Hishan Didulanga"
          role="Owner & Lead Guide"
          description="With years of experience in wildlife conservation and safari tours, Hishan leads our team with passion and expertise, ensuring every safari is an unforgettable adventure."
          image="/images/owner.png"
          contact="WhatsApp: +94714907880"
        />
      </motion.div>
    </motion.div>
  );
};

const TeamMember = ({ name, role, description, image, contact }) => {
  return (
    <motion.div 
      className="bg-white shadow-lg rounded-lg overflow-hidden"
      whileHover={{ scale: 1.05 }}
      transition={{ duration: 0.3 }}
    >
      <div className="h-64 bg-gray-200">
        <img src={image} alt={name} className="w-full h-full object-cover" />
      </div>
      <div className="p-6">
        <h3 className="font-bold text-xl mb-2">{name}</h3>
        <p className="text-gray-600 font-semibold mb-2">{role}</p>
        <p className="text-gray-600 mb-4">{description}</p>
        <p className="text-gray-800 font-semibold">{contact}</p>
      </div>
    </motion.div>
  );
};

export default About;
